import React from 'react'
import { motion } from "framer-motion"

import '../styles/MainBody.scss';
import { LatestYoutubeVideo, Products, MyProducts, Wallpapers, ExternalLink, SocialIcon } from './index.js';

export const MainBody = ({productsData, myProductsData, wallpapersData }) => {
  return (
    <div id='main-body'>
      <div id="latest-video-container" className=""
        initial={{y: '50vh'}}
        animate={{y: '0'}}
        exit={{y: '50vh'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <h3>Latest Video</h3>
        <LatestYoutubeVideo channelId="UCd69dlYu-Mqm2yrvQwRCJbw" />
      </div>
      <div className="sub-header"
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <ExternalLink id="print-button" url="https://www.beatstars.com/sulce" text="Beat Store" />
        {/* <ExternalLink id="wallpapers-button" url="https://sulce.gumroad.com" text="WALLPAPERS" /> */}
        {/* <ExternalLink id="pc-build-button" url="https://youtu.be/lAuoFRxsz-E" text="PC BUILD" /> */}
      </div>
      {/* <div id='product-wallpaper-container'
        initial={{y: '100vw'}}
        animate={{y: '0'}}
        exit={{x: '-100vw'}}
        transition={{type: "spring",stiffness: 30}}
      >
        <div id='product-cont'>
          <Products productsData={productsData} />
        </div>
      </div> */}
    </div>
  )
}

// export default Body
