import React from 'react'
import { motion } from "framer-motion"
import Typewriter from 'typewriter-effect';

import '../styles/Header.scss';
import '../styles/SocialIcon.scss';
import { SocialIcon } from './index.js';
import { CgMail } from "react-icons/cg";

export const Header = ({img, name, socialData}) => {
   
   const socailIconsElement = socialData.map((element, id) => {
      return <SocialIcon
         key={element.id}
         id={element.id} 
         className={element.className}
         icon={element.icon}
         url={element.url}
         checkLink={true}  
      />
   })


  return (
    <div id='header'>
      {/* background image */}
      <div id="top-layer-container">
         <motion.div id="header-top-layer" 
            initial={{opacity: 0}}
            animate={{ opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 0.2}}
         >
            <div id="header-top-half" className=''>
               <div id="profile-pic"></div>
               <div className='d-flex flex-column mx-2'>
                  <h3>SULCE</h3>
                  <motion.a id="mail-to" href="mailto:sulcebeats@gmail.com" whileHover={{scale: 1.01}} whileTap={{scale: 0.90}}>
                     <CgMail fontSize={18}/><h3>sulcebeats@gmail.com</h3>
                  </motion.a>
               </div>
            </div>
            <div className="social_container">
               {socailIconsElement}
            </div>
         </motion.div>
      </div>
    </div>
  )
}

// export default Header


