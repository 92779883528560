import { FaTiktok, FaYoutube, FaInstagram } from "react-icons/fa"; // Font Awesome 
import '../../styles/SocialIcon.scss';

export const socialIconData = [
   {
      "id": "Youtube",
      "className": "", 
      "icon": <FaYoutube />,
      "url": "https://youtube.com/@sulcebeats"
   },
   {
      "id": "Instagram",
      "className": "", 
      "icon": <FaInstagram />,
      "url": "https://www.instagram.com/sulcebeats"
   },
   {
      "id": "TikTok",
      "className": "", 
      "icon": <FaTiktok />,
      "url": "https://www.tiktok.com/@sulcebeats"
   }
]