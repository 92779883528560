
export const myProductListData = [
   {
      "id": "dr-stream-deck-profile",
      "title": "Davinci Resolve Basics Profile",
      "desc": "Free Stream Deck Profile - DR Basics Free Stream Deck Profile..",
      "className": "", 
      "img": require("../../imgs/MyProducts/DRStream-Deck-Profile.png"),
      "links": { 
         "url":"https://sulce.gumroad.com/l/dr-basics-stream-deck-profile",
         "amazon": "",
         "aliexpress": "",
      },
      "type": "all, software, setup, other", 
      "preview": "" 
   }
]